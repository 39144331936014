
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.modal {
  position: fixed;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  background: #ffffff;
  z-index: 101;
  padding: 20px;
  max-width: 375px;
  width: 100%;

  &__background {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #919191;
    z-index: 100;
    opacity: 0.7;
  }

  &__close {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 101;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 40px;
      background: #F97850;
      transform: rotate(45deg);
      right: 50%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 40px;
      background: #F97850;
      transform: rotate(135deg);
      right: 50%;
    }
  }
}