
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.customInput {

  input {
    border-radius: 0;
    outline: none;
    border: 1px solid;
    padding: 10px 20px;
    width: 100%;
  }
}