
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.main {
  min-height: 700px;
  margin-bottom: 100px;
  @include tablet {
    min-height: 400px;
    background-size: cover;
    margin-bottom: 50px;
  }

  &__hero--title {
    margin: 0;
    padding-top: 118px;
    padding-bottom: 200px;
    @include tablet {
      padding-bottom: 80px;
    }
    p {
      font-family: 'Teko', sans-serif;
      font-size: $size-font-big;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 62px;
      margin: 0;

      @include tablet {
        font-size: 34px;
        line-height: 34px;
      }
    }
  }
}