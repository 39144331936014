
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.formSubmitYourApplicationInput {
  padding-bottom: 10px;

  &__button {
    width: 100%;
  }
}

.customInput {
  padding-bottom: 10px;

  input {
    border-radius: 0;
    outline: none;
    border: 1px solid;
    padding: 10px 20px;
    width: 100%;
  }
}

.error {
  color: red;
}