
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.navbar {
  display: flex;
  width: 100%;

  &__logo {
    margin-right: 58px;
    cursor: pointer;
  }

  &__menu {
    flex: 1;
    border: none;
    background: none;

    @include notebook {
      display: none;
    }

    &--sub {
      font-weight: 700;
      font-size: 15px;
    }
  }

  &__header {
    background: none;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }

  &__contacts {
    display: flex;
    align-items: center;

    @include notebook {
      display: none;
    }

    p {
      font-size: 12px;
      opacity: .7;
      line-height: 1;
      text-align: right;
      margin: -19px 0;
    }

    a {
      text-align: right;
      margin: 3px 0;
      font-size: $size-font-main;
      line-height: 21px;
      font-weight: 700;
      color: $color-dark;

      &:hover {
        color: $color-main;
      }
    }
  }
}

.ant-row {
  width: 100%;
  @include notebook {
    justify-content: space-between!important;
    align-items: center;
  }
}