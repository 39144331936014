
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;500;700&display=swap');
@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
    font-family: $font-stack;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1210px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
}

.barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 8px;
    display: none;
    background: none;
    border-color: $color-main;

    &:hover {
        background: none;
        border-color: $color-main;
    }

    @include notebook {
        display: inline-block;
    }
}

.barsMenu>span {
    display: block;
}

.barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: $color-main;
    position: relative;
}

.barsBtn:after,
.barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: $color-main;
}

.barsBtn:after {
    top: auto;
    bottom: -6px;
}

.ant-drawer-content-wrapper {
    width: 320px!important;
}

.desktop {
    @include tablet {
        display: none;
    }
}

.mobile {
    display: none;
    @include tablet {
        display: inherit;
    }
}

.button {
    display: inline-block;
    padding: 20px 70px;
    background-color: #F97850;
    font-size: $size-font-main;
    font-weight: $font-weight-bold;
    color: $color-light;
    cursor: pointer;
    text-align: center;

    &:hover {
        opacity: 0.7;
        color: #ffffff;
    }
}

.section_top {
    background-size: cover;
    padding-bottom: 200px;
    background-repeat: no-repeat;

    @include tablet {
        background-size: cover;
        text-align: center;
        padding-bottom: 118px;
    }

    &__title {
        margin: 0 0 20px 0;
        padding-top: 118px;

        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;

            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }

    &__subtitle {
        font-size: $size-font-main;

        @include tablet {
            font-size: 14px;
        }
    }

    &__content {
        max-width: 500px;

        @include tablet {
            max-width: 100%;
        }
    }

    &__button {
        margin-top: 30px;
    }
}

.section_guarantee {
    padding: 50px 0;

    img {
        max-width: 257px;

        @include tablet {
            max-width: 111px;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;

        @include tablet {
            display: block;
        }
    }

    &__left {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 20px;

        @include tablet {
            justify-content: center;
            margin-bottom: 40px;
        }
    }

    &__right {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 20px;

        @include tablet {
            justify-content: center;
        }
    }

    &__title {
        margin: 0;
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;

            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }

    &__desc {
        p {
            font-size: $size-font-main;
            margin: 0;
            font-weight: $font-weight-bold;

            @include tablet {
                font-size: $size-font-large;
                line-height: 20px;
            }
        }
    }

    &__row {
        display: flex;

        @include tablet {
            display: block;
        }
    }

    &__subtitle {
        p {
            font-size: $size-font-small;
            line-height: 14px;
            margin: 0;
        }
    }

    &__info:not(:last-of-type) {
        margin-right: 35px;

        @include tablet {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__term {
        font-size: 40px;
        line-height: 52px;
        font-weight: $font-weight-bold;

        @include tablet {
            font-size: $size-font-large;
            line-height: 20px;
        }
    }
}

.section_windows_delivery {

    &__content {
        background: #75C9EB;
        border-radius: 10px;
        padding: 0 0 0 66px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include tablet {
            display: block;
            padding: 40px 20px;
            text-align: center;
        }
    }

    &__car {
        flex: 1;
        img {
            width: 100%;
            @include tablet {
                max-width: 300px;
            }
        }
    }

    &__title {
        margin: 0;
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;
            color: $color-light;

            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
        span {
            font-size: $size-font-main;
            max-width: 393px;
            width: 100%;
            display: block;
            color: $color-light;
            @include tablet {
                display: inline-block;
                font-size: 14px;
            }
        }
    }
}

.section_contact_us {
    margin-top: 100px;
    margin-bottom: 20px;
    @include tablet {
        margin-top: 50px;
    }
    &__content {
        background: #75C9EB;
        border-radius: 10px;
        padding: 140px 60px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        @include tablet {
            display: block;
            padding: 40px 20px;
            text-align: center;
        }
    }

    &__right {
        margin: 0 40px;
        @include tablet {
            margin: 0;
        }
    }

    &__title_wrap {
        margin: 0 40px;
        @include tablet {
            margin-bottom: 20px;
        }
    }

    &__title {
        margin: 0;
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;
            color: $color-dark;

            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }

    &__subtitle {
        margin: 0;
        p {
            font-size: 24px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 28px;
            margin: 0;
            color: $color-dark;

            @include tablet {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}

.section_slider {
    margin-bottom: 100px;
    margin-top: 100px;
    @include tablet {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    &__top {
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;
            color: $color-dark;
            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }
    &__content {
        img {
            width: 100%;
            padding: 0 10px;
        }
    }
}

.section_review {
    margin-top: 100px;
    margin-bottom: 100px;
    @include tablet {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__ava_wrap {
        height: 150px;
    }
    &__wrap {
        display: flex;
        @include mobileL {
            flex-direction: column;
        }
    }
    &__ava {
        margin-top: 5px;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__text {
        flex: 1;
        font-size: $size-font-main;
        line-height: 25px;
        margin: 0;
        @include mobileL {
            padding: 0 20px;
            margin-top: 20px;
        }
    }
    &__top {
        margin-bottom: 10px;
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;
            color: $color-dark;
            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }
    &__content {
        img {
            width: 100%;
            padding: 0 10px;
            @include mobileL {
                padding: 0 20px;
            }
        }
    }
}

.slick-next {
    right: 0!important;
    top: -30px!important;
    @include tablet {
        right: -20px!important;
        top: 50%!important;
    }
}

.slick-prev {
    right: 50px!important;
    top: -30px!important;
    left: auto!important;
    @include tablet {
        right: auto!important;
        top: 50%!important;
        left: -20px!important;
    }
}

.slick-prev:before, .slick-next:before {
    color: $color-main!important;
    font-size: 50px!important;
    @include tablet {
        font-size: 30px!important;
    }
}

.slick-prev, .slick-next {
    width: 50px!important;
    height: 50px!important;
    @include tablet {
        width: 30px!important;
        height: 30px!important;
    }
}

.service {
    padding: 95px 0 103px;
    background-color: rgba(196, 196, 196, 0.2);
    overflow: hidden;

    &__top {
        margin-bottom: 10px;
        text-align: center;
        p {
            font-family: 'Teko', sans-serif;
            font-size: $size-font-big;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            line-height: 62px;
            margin: 0;
            color: $color-dark;
            @include tablet {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mobileL {
            justify-content: center;
        }


        p {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;
            margin-top: 15px;
            max-width: 150px;
            width: 100%;
        }
    }

    &__button {
        margin-top: 40px;
        text-align: center;
    }
}

.buttonWrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;

    a {
        margin-bottom: 10px!important;
    }
}